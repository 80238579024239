<template>
  <div class="wrap__contents bet-history--inner bet-history-update">
     <!-- <div class="tabs">
      <div
        class="tabs__item"
        :class="{ 'is-active' : $route.params.status === 'all' }"
      >
        <router-link :to="`/bet-history-update/${$route.params.game}/all`"></router-link>
        <span>
          {{ translate(translations, 'all') }}
        </span>
      </div>
      <div
        class="tabs__item"
        :class="{ 'is-active' : $route.params.status === 'waiting' }"
      >
        <router-link :to="`/bet-history-update/${$route.params.game}/waiting`"></router-link>
        <span>
          {{ translate(translations, 'waiting') }}
        </span>
      </div>
      <div
        class="tabs__item"
        :class="{ 'is-active' : $route.params.status === 'ongoing' }"

      >
        <router-link :to="`/bet-history-update/${$route.params.game}/ongoing`"></router-link>
        <span>
          {{ translate(translations, 'ongoing') }}
        </span>
      </div>
      <div
        class="tabs__item"
        :class="{ 'is-active' : $route.params.status === 'done' }"
      >
        <router-link :to="`/bet-history-update/${$route.params.game}/done`"></router-link>
        <span>
          {{ translate(translations, 'done') }}
        </span>
      </div>
    </div>tabs -->

    <div class="">
      <div class="toolbar">
        <div class="toolbar__items autocomplete-wrap">
          <autocomplete
            v-model="selectedCountry"
            ref="autocompleteCountry"
            :label="translate(translations, 'country')"
            :items="autoCompletes[0].data"
            @setRes="selectedCountry=$event, trigger()"
          />

          <autocomplete
            v-model="selectedGameName"
            ref="autocompleteGame"
            :label="translate(translations, 'game name')"
            :items="autoCompletes[1].data"
            @setRes="selectedGameName=$event, trigger()"
          />

          <autocomplete
            v-model="selectedLeague"
            ref="autocompleteLeague"
            :label="translate(translations, 'league')"
            :items="leaguesList"
            @setRes="selectedLeague=$event, trigger()"
          />
        </div>

        <div class="toolbar__items searchbar">
          <search
            :translations="translations"
            :dateBy="dateBy"
            :filterBy="($route.params.type === 'mini') ? filterByAlt : filterBy"
            @refresh="search"
            @trigger="trigger"
            @clear="clear"
          >
          </search>
        </div>
      </div>

      <check-data-list
        v-if="(isDataLoading || isEmpty)"
        :isLoading="isDataLoading"
        :isEmpty="isEmpty"
      >
      </check-data-list>
      <div class="table-wrap" v-else>
        <table class="historytbl"  v-if="$route.params.game !=='mini'">
          <thead>
            <tr>
              <th class="status">
                <span>{{translate(translations, 'status')}}</span>
              </th>
              <th class="date">
                <span>{{translate(translations, 'play date')}}</span>
              </th>
              <th class="type">
                <span>{{translate(translations, 'type')}}</span>
              </th>
              <th class="game">
                <span>{{translate(translations, 'game')}}</span>
              </th>
              <th class="league">
                <span>{{translate(translations, 'league')}}</span>
              </th>
              <th class="lost-exception">
                <span>{{translate(translations, 'lost exception')}}</span>
              </th>
              <th class="home">
                <span>{{translate(translations, 'home')}}</span>
              </th>
              <th class="tie">
                <span>{{translate(translations, 'tie')}}</span>
              </th>
              <th class="away">
                <span>{{translate(translations, 'away')}}</span>
              </th>
              <th class="modify">
                <span>{{translate(translations, 'modify')}}</span>
              </th>
              <th class="bet-count">
                <span>{{translate(translations, 'bet count')}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="match in formatted">
              <td class="status">
                <span v-if="match.match_active">{{translate(translations,match.match_game_status)}}</span>
                <span v-else>{{translate(translations, 'stop')}}</span>
              </td>
              <td class="play-date">{{match.match_play_date}}</td>
              <td class="type">
                <template v-if="lang === 'en'">
                  {{ match.type_eng }}
                </template>
                <template v-if="lang === 'kr'">
                  {{ match.type_kor ? match.type_kor : match.type_eng }}
                </template>
              </td>
              <td class="game">
                <img v-if="match" :src="basePath + match.game_img">
                <template v-if="lang === 'en'">
                  {{match.game_eng}}
                </template>
                <template v-if="lang === 'kr'">
                  {{ match.game_kor ? match.game_kor : match.game_eng }}
                </template>
              </td>
              <td class="league">
                <img v-if="match" :src="basePath + match.league_img">
                <template v-if="lang === 'en'">
                  {{match.league_eng}}
                </template>
                <template v-if="lang === 'kr'">
                  {{ match.league_kor ? match.league_kor : match.league_eng }}
                </template>
              </td>
              <td class="lost-exception">
                <span class="lost">
                  ({{match.bet_amount_red | formatAmount('whole')}})
                </span>
                <span class="win">
                  {{match.bet_amount_green | formatAmount('whole')}}
                </span>
              </td>
              <td class="home">
                <table class="sub-table" @click="open(match.info.Home.betGroup)">
                  <tr>
                    <td class="team-name">
                      <template v-if="lang === 'en'">

                        {{match.home_eng}}
                        <span v-if="match.match_game_type !== 'odd'" class="bet-highlight">({{match.info.Home.name}})</span>

                      </template>
                      <template v-if="lang === 'kr'">

                        {{match.home_kor ? match.home_kor : match.home_eng}}
                        <span v-if="match.match_game_type !== 'odd'" class="bet-highlight">({{match.info.Home.name}})</span>

                      </template>
                    </td>
                    <td class="total-bets">

                      <span>{{match.info.Home.amount | formatAmount('whole')}}</span>

                    </td>
                    <td class="odds">

                      <span>{{match.info.Home.odd}}</span>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="tie" @click="open(match.info.Draw.betGroup)">
                <table class="sub-table">
                  <tr>
                    <td class="total-bets">
                     <span v-if="match.info.Draw.odd !== ''
                     && match.match_game_type !== 'handicap'
                     && match.match_game_type !== 'over/under'" >
                        {{match.info.Draw.amount | formatAmount('whole')}}
                     </span>

                    </td>
                    <td class="odds">
                        <span>{{match.info.Draw.odd}}</span>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="away">
                <table class="sub-table" @click="open(match.info.Away.betGroup)">
                  <tr>
                    <td class="team-name">
                      <template v-if="lang === 'en'">
                        {{match.away_eng}}
                        <span v-if="match.match_game_type !== 'odd'" class="bet-highlight">({{match.info.Away.name}})</span>
                      </template>
                      <template v-if="lang === 'kr'">

                        {{match.away_kor ? match.away_kor : match.away_eng}}
                        <span v-if="match.match_game_type !== 'odd'" class="bet-highlight">({{match.info.Away.name}})</span>


                      </template>
                    </td>
                    <td class="total-bets">
                      <span>{{match.info.Away.amount | formatAmount('whole')}}</span>
                    </td>
                    <td class="odds">
                      <span>{{match.info.Away.odd}}</span>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="modify">
                <button class="btn--default">Modify</button>
              </td>

              <td class="bet-count">

                {{match.info.Home.betCount+match.info.Away.betCount+match.info.Draw.betCount}}

              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- for mini-->
      <div
        class="gamelist--inner"
        v-for="match in matches"
        v-if="$route.params.game === 'mini'"
      >
        <div class="gamelist-head">
          <div class="gamelist-row row-head">
            <div class="gamelist-col status">status</div>
            <div class="gamelist-col type">type</div>
            <div class="gamelist-col game">game</div>
            <div class="gamelist-col play-date" v-if="$route.params.game != 'mini'">Play date</div>
            <div class="gamelist-col teams">Teams</div>
            <div class="gamelist-col country">country</div>
            <div class="gamelist-col league">league</div>
          </div>
        </div>
        <bet-history-new-mini
          :match="match"
        >
        </bet-history-new-mini>
      </div>

      <modal
        class="modal--create-user"
        :title="''"
        v-if="showBetHistory"
        :activate="showBetHistory"
        @activate="showBetHistory = $event"
      >
        <template slot="body">
          <bet-list-new></bet-list-new>
        </template>

        <template slot="footer">
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
import Autocomplete from '@/components/base/autocomplete/Autocomplete'
import Modal from '@/components/base/Modal'

import BetListNew from '@/views/bet-history/BetListNew'

//= mixins
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

import BetHistoryNewMini from '@/views/bet-history/BetHistoryNewMini'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

import FormatAmount from '@/assets/js/filters/FormatAmount'

export default {
  name: 'BetHistory',

  components: {
    BetslipCard,
    BetsCard,
    Pagination,
    Search,
    CheckDataList,
    Autocomplete,
    BetListNew,
    Modal,
    BetHistoryNewMini
  },

  mixins: [
    getStyles,
    getListStatus
  ],

  data () {
    let params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'created_at',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,
      selectedCountry : '',
      selectedGameName: '',
      selectedLeague: '',
      leaguesList : [],
      autoCompletes: [
        {
          key: 'country',
          data: ['...loading']
        },
        {
          key: 'game_name',
          data: ['...loading']
        },
        {
          key: 'league',
          data: ['...loading']
        }
      ],

      filterBy: [
        // {
        //   value: 'login_id',
        //   display: 'login id'
        // },
        // {
        //   value: 'login_name',
        //   display: 'login name'
        // },
        // {
        //   value: 'bet_amount',
        //   display: 'bet amount'
        // },
        // {
        //   value: 'win_amount',
        //   display: 'win amount'
        // },
        {
          value: 'home_team',
          display: 'home team'
        },
        {
          value: 'away_team',
          display: 'away team'
        },
      ],

      filterByAlt: [
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        }
      ],

      dateBy: [
        {
          value: 'play_date',
          display: 'play date'
        },
        // {
        //   value: 'created_at',
        //   display: 'bet date'
        // },
        // {
        //   value: 'result_date',
        //   display: 'result date'
        // }
      ],

      status: '',
      filter: '',

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      showBetHistory: false
    }
  },

  filters: {
    total (amounts) {
      if (!amounts || !amounts.length) {
        return 0
      }
      return amounts.reduce((e = 0, a = 0) => {
        return parseInt(e) + parseInt(a)
      })
    },

    FormatAmount,

    teams (item, lang) {
      if (lang === 'en' ) {
        return `${item.home_team_name} vs ${item.away_team_name}`
      }

      if (lang === 'kr' ) {
        return `${item.home_team_name_kor ? item.home_team_name_kor : item.home_team_name }
          vs
        ${item.away_team_name_kor ? item.away_team_name_kor : item.away_team_name}`
      }
      return item
    },

    totalBets (bets, key) {
      let _b = null
      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            console.log(e, ' && !e.isTestUser')
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }
      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
        }
      }
      if (key === 'tie') {
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'draw'
          })
        }
      }

      if (bets.length === 1 && key === 'home') {
        let key = Object.keys(_b)[0]
        let betAmts = _b[key].map(e => { return e.bet_amount })
        return betAmts.reduce((a, e) => {
          return a + e
        }, 0)
      }
      if (bets.length === 1 && key === 'away') {
        return 0
      }

      if (_b === null) {
        return
      }
      let _k = Object.keys(_b)[0]
      let betAmts = _b[_k].filter(e => {
        return !e.isCancel && !e.isTestUser
      }).map(e => { return e.bet_amount })
      return betAmts.reduce((a, e) => {
        return a + e
      }, 0)
    },

    betArea (bets, key) {
      let _b = null
      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }
      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
        }
      }

      if (bets.length === 1 && key === 'home') {
        let key = Object.keys(_b)[0]
        return key
      }
      if (bets.length === 1 && key === 'away') {
        return '-'
      }

      let _k = Object.keys(_b)[0]
      return _k
    },

    matchOdds (test, odds, bets) {
      let data = null
      for (let key in odds) {
        data = odds[key][test]
      }
      if (!data) {
        return '-'
      }

      let key = 'odd'
      if (bets.length) {
        let _k = bets[0].betArea.split('@')[0]
        data = odds[_k][test]
      }

      return data.V
    }
  },

  computed: {
    ...mapState('bet-history-new', ['matches', 'formatted']),
    ...mapState('game', {gameTypes: 'types'}),
    ...mapState('config', ['config'])
  },

  watch: {
    $route ($router, from) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      let flag = false
      if ($router.query.mgIds) {
        flag = true
      }
      this.search(this.generate($router.query), flag)
    },
  },

  async created () {
    this.params = this.generate(this.params)
    this.params.sort_by = 'created_at'
    this.params.lang = this.lang || 'en'
    this.autoCompletes = await this.getAC()
    this.search()
    this.getTypes()
  },

  methods: {
    ...mapActions('bet-history-new', { getBetHistoryNew :'get', getBetList: 'getBetList' }),
    ...mapActions('game', ['getTypes']),
    ...mapActions('bet-history',
      ['get', 'restore', 'cancel', 'delete','generateAutoComplete','generateLeagues']),

    getWinTeam (matchScore, match) {
      matchScore = typeof matchScore === 'string' ? JSON.parse(matchScore) : matchScore
      if (!matchScore) {
        return '-'
      }

      if (matchScore.final && matchScore.final.Home && matchScore.final.Away) {
        if (matchScore.final.Home < matchScore.final.Away) {
          return match.away_team_name
        }
        if (matchScore.final.Away < matchScore.final.Home) {
          return match.home_team_name
        }

        if (matchScore.final.Away === matchScore.final.Home) {
          return 'Draw'
        }
      }
      return '-'
    },

    getTotalBet (match) {
      let total = match.betsRaw.filter(e => {
        return !e.isCancel && !e.isTestUser
      }).map(e => {
        return e.bet_amount
      })

      console.log(total, 'asdasdasdasd*********************')

      return total.reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)
    },

    getTotalLose (match) {
      let total = match.betsRaw.filter(e => {
        return e.status === 'l' && !e.isCancel
      })

      let totalAll = match.betsRaw.filter(e => {
        return !e.isCancel && !e.isTestUser
      })

      let lose = total.map(e => { return e.bet_amount }).reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)
      let tot = totalAll.map(e => { return e.bet_amount }).reduce((e, a) => {
        return parseInt(e) + parseInt(a)
      }, 0)

      return tot - lose
    },

    getBetArea (bets, key) {
      let _b = null
      if (key === 'home') {
        _b = bets[0]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'home'
          })
        }
      }
      if (key === 'away') {
        _b = bets[1]
        if (bets.length === 3) {
          _b = bets.find(e => {
            return Object.keys(e)[0].toLowerCase() === 'away'
          })
          console.log(_b, '##################')
        }
      }

      if (bets.length === 1 && key === 'home') {
        let key = Object.keys(_b)[0]
        return key
      }
      if (bets.length === 1 && key === 'away') {
        return '-'
      }

      let _k = Object.keys(_b)[0]
      return _k
    },

    checkMidVal (odds, betsRaw) {
      if (betsRaw.length) {
        return betsRaw[0].betArea.split('@')[0]
      }
    },

    open (groups) {
      if(!groups.length) return
      let mgIds = groups
      this.showBetHistory = true
      this.$router.push({ query: { mgIds } })

    },

    async search (query, flag = false) {
      this.params = query || this.params
      if (!flag) {
        this.showDataLoading = true
        this.isDataLoading = true
      }
      this.isEmpty = false
      await this.getBetHistoryNew({
        type: this.$route.params.game,
        status: this.$route.params.status,
        params: {
          country: this.selectedCountry,
          game: this.selectedGameName,
          league: this.selectedLeague,
          query: this.params
        }
      })
      this.isDataLoading = false
      this.showDataLoading = false

      if (this.$route.params.game !== 'mini') {
        if (!this.formatted.length) {
          this.isEmpty = true
        }
      }  else {
        if (!this.matches.length) {
          this.isEmpty = true
        }
      }
    },

    clear () {
      this.selectedCountry = ''
      this.selectedGameName = ''
      this.selectedLeague = ''

      this.$refs.autocompleteCountry.search = ''
      this.$refs.autocompleteLeague.search = ''
      this.$refs.autocompleteGame.search = ''
    },

    async getAC () {
      this.generateLeagues({
        country: this.selectedCountry || '',
        game: this.selectedGameName || ''
      }).then(r => {
        if (r.length) {
          this.leaguesList = r
        }
      })
      return await this.generateAutoComplete()
    },

    async trigger (q) {
      this.params = q || this.params

      this.params.country = this.selectedCountry || ''
      this.params.game = this.selectedGameName || ''
      this.params.league = this.selectedLeague || ''
      this.params.lang = this.lang || 'en'
      this.$router.push({ query: this.params })
    },
  },
}
</script>
<style type="text/css" lang="scss" scoped>
  .bet-history--inner .gamelist--inner {
  }
  img {
    margin-right: 10px;
    width: 20px;
  }
  .has-bet {
    font-weight: 12px;
    font-weight: bold;
    color: #c9e3ec
  }
  .matchgame__item:hover {
    background-color: #434c5f;
    cursor: pointer;
    .matchgame__lbl,
    .matchgame__data {
      background: #3a4354;
    }
  }

  .game, .country, .league {
    width: 100px;
    max-width: 100px;
  }
  .modal--inner {
    width: 100%;
    max-width: 1200px;
  }

  .bet-list-wrap {
    min-height: 200px;
  }
</style>
