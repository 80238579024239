<template>
  <div class="autocomplete">
    <div class="input-wrap">
      <input
        :disabled="items.length && isDisabled"
        :placeholder="label"
        type="text"
        v-model="search"
        @input="onChange"
        @click="onChange"
        @focus="onChange"
        @keydown.tab="handleBlur"
        @keydown.enter="onEnter"
        @keydown.up="onArrowUp"
        @keydown.down="onArrowDown"
      />
    </div>

    <ul
      :id="label+'-autocomplete-results'"
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        class="loading"
        v-if="isLoading"
      >
        Loading results...
      </li>

      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ language === 'kr' ? result.kor : result.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

//= mixins
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

export default {
  name: 'autocomplete',

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    label: {
      type: String,
      required: false,
      default: () => 'select'
    },

    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data () {
    return {
      scrollLimit: 72,
      currentHeight: 0,
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: 0
    };
  },

  computed: {
    ...mapState('config', ['config']),
    language () {
      return this.config.lang || vueLocalStorage.getItem('agentLang')
    }
  },

  watch: {
    items: function (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    },

    search: function (val, oldValue) {
      this.$emit('input', val)
    },

    arrowCounter : function (val, prev) {
      let ul = document.getElementById(this.label+'-autocomplete-results')
      let item = ul.children
      let height = 22
      let direction = 'down'
      if (item.length && item[val]) {
        height = item[val].clientHeight
      }

      if (val < prev) {
        height = height  * -1
        direction = 'up'
      }

      this.currentHeight += height

      if (this.currentHeight < this.scrollLimit  && direction === 'up') {
        this.scrollLimit = this.scrollLimit + height
        ul.scrollTop = this.scrollLimit
      }

      if(this.currentHeight > this.scrollLimit && direction === 'down') {
        ul.scrollTop = this.scrollLimit
        this.scrollLimit = this.scrollLimit+height
      }
    }
  },

  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },

  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },


  methods: {
    onClick () {
      this.$emit('input', this.search)

      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true
      } else {
        // Let's  our flat array
        this.filterResults()
        this.isOpen = true
      }
    },

    onChange () {
      // Let's warn the parent that a change was made
      this.$emit('input', this.search)

      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true
      } else {
        // Let's  our flat array
        this.filterResults()
        this.isOpen = true
      }
    },

    clear () {
      this.search = ""
    },

    filterResults () {
      if (!this.search) {
        this.results = this.items
        return
      }
      // first uncapitalize all the things
      this.results = this.items.filter((item) => {
        item.kor = item.kor === null ? '' : item.kor
        // return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        // return this.language === 'kr' ? item.kor.indexOf(this.search) > -1
        //   : item.name.indexOf(this.search) > -1
        return item.kor.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },

    setResult (result) {
      let res = this.language === 'kr' ? result.kor : result.name
      this.$emit('setRes', res)
      this.search = res
      this.isOpen = false
      this.scrollLimit = 72
      this.currentHeight = 0
    },

    onArrowDown (evt) {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },

    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter -1
      }
    },

    onEnter () {
      if (this.search !== '' || this.arrowCounter > 0 ) {
        let result = { name: this.search, kor: this.search }
        if (this.results[this.arrowCounter] !== undefined) {
          result = this.results[this.arrowCounter]
        }
        this.setResult(result)
      } else {
        this.setResult({ name: '', kor: '' })
      }
      this.isOpen = false
      this.arrowCounter = 0//-1
      this.scrollLimit = 72
      this.currentHeight = 0
      let ul = document.getElementById(this.label+'-autocomplete-results')
      ul.scrollTop = 0
    },

    handleClose () {
      if (this.isOpen) {
        // this.isOpen = false;
        this.arrowCounter = 0//-1
        this.scrollLimit = 72
        this.currentHeight = 0

        let ul = document.getElementById(this.label+'-autocomplete-results')
        ul.scrollTop = 0
      }
    },

    handleBlur () {
      this.isOpen = false
      this.arrowCounter = 0//-1
      this.scrollLimit = 72
      this.currentHeight = 0
    },

    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = 0//-1
        this.scrollLimit = 72
        this.currentHeight = 0
        let ul = document.getElementById(this.label+'-autocomplete-results')
        ul.scrollTop = 0
      }
    }
  }
}
</script>
